@use "~@otto-ec/global-frontend-settings/scss/variables" as variables;
@use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl;
@use "~@otto-ec/global-frontend-settings/scss/grid" as grid;
@use "~@otto-ec/global-frontend-settings/gfs" as gfs;
@use "mixins" as mixins;

.nav_local-content {
  color: variables.$black50;
  display: block !important;
  @include gfs.font-size(variables.$font100);
  overflow-x: hidden;
  background-color: white;
  margin: 0;
  padding: 0 0 0 16px;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    color: pl.$pl_color-black100;
    margin: 0;
    padding-left: 0;
  }
}

.nav_local-navigation-headline {
  color: pl.$pl_color-red100;
  display: none;
  font-weight: bold;
  @include gfs.font-size(variables.$font125);
  margin: 0;
  padding: 3px 5px;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    display: block;
    border-bottom: 1px solid pl.$pl_color-grey200;
    @include gfs.font-size(variables.$font100);
  }

  & > a {
    color: variables.$black50;
    display: inline-block;
    text-decoration: none;
  }
}

.nav_local-category {
  @include gfs.breakpoint(gfs.$breakpoint-l) {
    padding-bottom: 20px;
  }
}

.nav_local-links .nav_local-link {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid pl.$pl_color-grey200;
  padding-left: 0;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    border-bottom: none;
  }

  p, a, .ub64e {
    @include mixins.nav-local-link-el;
  }

  &.nav_with-count {
    p, a, .ub64e {
      justify-content: space-between;

      @include gfs.breakpoint(gfs.$breakpoint-l) {
        justify-content: flex-start;
      }
    }

    .nav_link-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .nav_link-count {
      padding-right: 16px;

      &:after {
        color: pl.$pl_color-grey400;
        padding-left: 4px;
        content: attr(data-count);
        @include gfs.breakpoint(gfs.$breakpoint-l) {
          content: "(" attr(data-count) ")";
        }
      }
    }
  }

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    display: block;
    padding-left: 0;

    p, a {
      padding: 3px 5px;

      .touchable & {
        padding: 10px 5px;
      }
    }

    .ub64e {
      padding: 3px 5px;

      .touchable & {
        padding: 10px 5px;
      }
    }
  }

  &.selected {
    color: pl.$pl_color-red100;
    font-weight: bold;
  }
}

.nav_local-links li.nav_local-link:hover {
  @include gfs.breakpoint(gfs.$breakpoint-l) {
    background-color: variables.$grey50;

    &.selected {
      background-color: inherit;
    }
  }
}

.nav_local-links .nav_link-headline {
  @include mixins.nav-link-headline;

  &.selected {
    display: none;

    & ~ .nav_local-link {
      display: block;
      border-bottom: 1px solid pl.$pl_color-grey200;
    }
  }

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    &:hover {
      border-bottom: 1px solid variables.$grey50;
    }

    a, p, .ub64e {
      font-weight: bold;
    }

    &.selected {
      display: block;

      &:hover {
        border-bottom: 1px solid pl.$pl_color-grey200;
      }

      & ~ .nav_local-link {
        border-bottom: none;
      }
    }
  }
}

.nav_local-hidden {
  display: none;
  height: 0;
  overflow: hidden;
  margin-bottom: 4px;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    display: block;

    transition-property: height;
    transition-duration: 0.5s;
  }
}

.nav_local-expand {
  display: none;
  cursor: pointer;
  font-weight: bold;
  padding: 0 0 0 6px;
  margin: 0;
  text-decoration: underline;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    display: block;
  }
}

.nav_local-touch-container {
  overflow: hidden;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    overflow: inherit;
  }
}

.nav_local-accordion-expand {
  display: block;
  border: none;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    display: none;
  }
}
