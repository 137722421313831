@use "../../node_modules/@otto-ec/global-frontend-settings/scss/grid" as grid;
@use "~@otto-ec/global-frontend-settings/gfs" as gfs;

.nav_only-l-xl {
  display: none !important;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    display: block !important;
  }
}

.nav_only-s-m {
  visibility: unset !important;
  @include gfs.breakpoint(gfs.$breakpoint-l) {
    display: none;
  }
}
