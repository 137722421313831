@use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl;

@mixin nav-local-link-el {
  display: flex;
  align-items: center;
  text-decoration: none;

  margin: 0;
  padding: 12px 0;
}

@mixin nav-link-headline {
  display: block;
  border-bottom: 1px solid pl.$pl_color-grey200;
}
