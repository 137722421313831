@use "~@otto-ec/global-frontend-settings/scss/grid" as grid;
@use "~@otto-ec/global-frontend-settings/gfs" as gfs;

.nav_menu-expander {
  &__pane {
    overflow: hidden;
  }

  &__cluster-more-button {
    padding: 16px 16px 12px 0;

    @include gfs.breakpoint(gfs.$breakpoint-l) {
      padding-top: 0;
      padding-left: 5px;

      .touchable & {
        padding: 10px 5px;
      }
    }

  }
}